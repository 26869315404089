.content-wrapper {
    min-width: 100%;
    padding: 0;
}

.content-wrapper > .row:first-child {
    height: 2rem;
}

.nav-tabs {
    border-bottom: 1px solid #31547C;
    flex-shrink: 0;
}
.nav-tabs > .nav-item > .nav-link {
    border-radius: 0;
    border-bottom: 1px solid #31547C;
    font-family: "NotoSansKR-Medium";
    font-size: 0.8rem;
    color: #31547C;
}
.nav-tabs > .nav-item > .nav-link.active {
    background-color: #31547C;
    color: white;
}
.nav-tabs > .nav-item > .nav-link:hover {
    border: 1px solid #31547C;
}
.tab-title {
    width: 6rem;
}
.tab-close {
    display: flex;
    padding-top: 0.1rem !important;
}
.tab-close:hover {
    border: 1px solid #31547C;
}
.tab-close.active:hover {
    border: 1px solid #ffffff;
}
.tab-close > img {
    width: 100%;
}

.tab-content {
    flex-grow: 1;
}

.tab-content > .tab-pane {
    height: 100%;
}