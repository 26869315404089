.calendar {
    width: 100%;
    margin: 0 auto;
}

.calendar td {
    height: 5rem;
}

.calendar-header {
    margin: 10px 0;
}

.calendar-header span {
    font-size: 1.1rem;
}

.calendar-header svg {
    cursor: pointer;
}

.disabled-day > span {
    color: #d3d3d3;
}

.today {
    background-color: rgba(206, 171, 131, 0.5) !important;
    font-weight: bold;
}

.days-header > th {
    background-color: #31547C !important;
    color: white !important;
}

.days {
    font-size: 0.9rem;
}