@font-face {
    font-family: "NotoSansKR-Regular";
    src: url("./assets/fonts/NotoSansKR-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "NotoSansKR-Medium";
    src: url("./assets/fonts/NotoSansKR-Medium.ttf") format("truetype");
    font-weight: normal;
}

body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    font-family: "NotoSansKR-Regular";
}

#root {
    width: 100%;
    height: 100%;
}

.main-button {
    background-color: #31547C !important;
    font-family: "Roboto-Medium" !important;
}

.main-button:hover {
    background-color: #ceab83 !important;
    border-color: #ceab83 !important;
    color: white !important;
}

.fill-background {
    background-color: #31547C;
    color: white;
}

.all-border {
    border: 1px solid #31547C;
    border-radius: 0.375rem;
}

.right-border {
    border-right: 1px solid #31547C;
}

.bottom-border {
    border-bottom: 1px solid #31547C;
}

.delimiter {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #888888;
}
.vertical-delimiter {
    height: 100%;
    width: 1px;
    background-color: #306244;
    margin: 0 auto;
}

.bold-text {
    font-family: "NotoSansKR-Medium";
    font-size: 1.2rem;
}

.small-text {
    font-size: 0.8rem;
}

.wrapper {
    min-width: 100%;
}

.modal-content {
    border: 1px solid #31547C;
    border-radius: 0px;
}
.modal-header {
    padding: 8px;
    border-bottom: 1px solid #31547C;
}
.modal-title {
    font-family: "NotoSansKR-Medium";
    font-size: 1.2rem;
}
.modal-body {
    font-size: 1rem;
}
.modal-footer {
    border-top: 1px solid #31547C;
    padding: 6px;
}

.toast-container {
    bottom: 1.5rem !important;
    right: 1.5rem !important;
}
.toast-wrapper {
    border: 1px solid #31547C;
    border-radius: 0px;
}
.toast-wrapper > .toast-header {
    background-color: #31547C;
    border-bottom: 1px solid #31547C;
    border-radius: 0px;
    color: white;
}

.form-select {
    border-color: #31547C;
    font-size: 0.9rem;
}

.date-button {
    background-color: white;
    color: #31547C;
    border: 1px solid #31547C;
    font-size: 0.9rem;
}
.date-button:hover {
    background-color: #31547C;
    color: white;
}
.rdrMonthAndYearPickers, .rdrDateInput {
    font-size: 0.9rem;
}

.input-group > span {
    background-color: #31547C;
    color: white;
    border: 1px solid #31547C;
}
.input-group > input[type="text"] {
    border: 1px solid #31547C;
}

input[type="text"], input[type="file"], input[type="number"] {
    border: 1px solid #31547C;
}

.table-wrapper {
    overflow: auto;
}
.table-wrapper::-webkit-scrollbar {
    width: 0.6em;
    height: 0.6em;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background-color: #31547C;
    border: 1px solid white;
    border-radius: 5px;
}
.table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #ceab83;
}

.table-wrapper::-webkit-scrollbar-track {
    background-color: rgba(48, 98, 68, 0.2);
}

.long-table-data {
    width: max-content !important;
    border-collapse: separate;
    border-spacing: 0;
}
.table-data {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}
thead > tr:first-child th {
    position: sticky;
    top: 0;
}
.long-table-data > thead > tr:last-child th {
    position: sticky;
    top: 38.61px
}
.single-header > thead > tr:first-child th {
    position: sticky !important;
    top: 0 !important;
}
th {
    background-color: #31547C !important;
    font-family: "NotoSansKR-Regular";
    font-weight: normal;
    font-size: 0.9rem;
    color: white !important;
    vertical-align: middle;
    text-align: center;
    border-right: 1.5px solid white;
}
td {
    font-family: "NotoSansKR-Regular";
    font-weight: normal;
    font-size: 0.9rem;
    vertical-align: middle;
    text-align: center;
    height: 2rem;
}

.fixed-column {
    position: sticky;
    z-index: 10;
}
.table-total-row {
    position: sticky;
    bottom: 0;
    z-index: 10;
}
.table-total-row > td {
    background-color: #adb5bd;
    color: white;
    font-weight: bold;
}

.apexcharts-datalabels > text {
    font-family: "NotoSansKR-Regular" !important;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialog-search-input {
    border-color: #31547C !important;
    font-size: 0.9rem !important;
}

.has-search-result {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none !important;
}
.search-result-wrapper {
    position: absolute;
    background-color: white;
    border: 1px solid #31547C;
    border-radius: 0.375rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
}
.search-result-item {
    height: 2rem;
    line-height: 2rem;
    cursor: pointer;
}
.search-result-item:hover {
    background-color: rgba(49, 84, 124, 0.5);
    color: black;
}

.table-checkbox {
    margin-top: 0 !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.table-checkbox:checked {
    background-color: #31547C !important;
    border-color: #31547C !important;
}

.table-checkbox.all-check:checked {
    background-color: #ceab83 !important;
    border-color: #ceab83 !important;
}

.title-comment {
    font-size: 0.8rem;
    font-weight: normal;
}

.big-modal {
    --bs-modal-width: 1000px !important;
}

.image-wrapper {
    width: 100%;
    height: 200px;
}
.image-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.context-button {
    display: flex;
    width: 120px;
    height: 30px;
    background-color: white;
    border: 1px solid #31547C;
    border-radius: 0.375rem;
    color: black;
    justify-content: center;
    align-items: center;
}
.context-button:hover {
    background-color: #31547C;
    color: white;
}
.context-button:first-child {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.context-button:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs {
    z-index: 1;
}