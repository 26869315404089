.main-wrapper {
    min-width: 100%;
    height: 100%;
}
.main-wrapper > .row {
    height: 100%;
}
.main-wrapper > .row > div {
    padding: 0 !important;
}
.main-wrapper > .row > div:first-child {
    width: 10% !important;
}
.main-wrapper > .row > div:last-child {
    width: 90% !important;
}